import React from 'react'
import styled from 'styled-components'

import img1 from '../assets/geno-scanlines.svg';

const GlitchWrap = styled.div`
position: relative;
`

const GenoHeading = styled.h1`
position: relative;
    -webkit-animation: glitch-skew 1s infinite linear alternate-reverse;
    animation: glitch-skew 1s infinite linear alternate-reverse;
    font-size: 24px;
    word-wrap: normal;
    width: 100%;
    position: relative;
    z-index: 1;
    font-family: 'mokoto glitch mark I', Arial, Helvetica, sans-serif;
    background: -webkit-linear-gradient(45deg, #ff0468, #f081ff);
    opacity: 0.8;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 52px;
    letter-spacing: 0.1em;
    word-wrap: normal;
    white-space: nowrap;
    word-break: keep-all;
    text-align: center;

    &::before{
    content: "GENOVERSE";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    left: 2px;
    opacity: 0.5;
    clip: rect(44px, 450px, 56px, 0);
    -webkit-animation: glitch-anim 4s infinite linear alternate-reverse;
    animation: glitch-anim 4s infinite linear alternate-reverse;
    text-shadow: -2px 0 #ff0468;
    word-wrap: normal;
    }

    *::before{
    border-width: 0;
    border-style: solid;
    box-sizing: border-box;
    }

    *::after{
    border-width: 0;
    border-style: solid;
    box-sizing: border-box;
    }

    ::after{
    content: "GENOVERSE";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    left: -2px;
    -webkit-animation: glitch-anim2 3s infinite linear alternate-reverse;
    animation: glitch-anim2 3s infinite linear alternate-reverse;
    opacity: 0.4;
    text-shadow: -2px 0 #f081ff, 2px 2px #ff0063;
    word-wrap: normal;
    border-color: #E2E8F0;
    }

    .animation{
        font-family: mokoto glitch outline;
	font-weight: 400;
	font-size: 30px;
	line-height: 1.1;
	letter-spacing: 4px;
	margin-left: var(--chakra-space-2);
	position: absolute;
	top: 0px;
	left: 0px;
	width: var(--chakra-sizes-full);
	opacity: 0.4;
	white-space: nowrap;
	animation: 5s linear 0s infinite alternate-reverse none running glitch-anim2;
    }
`
const GenoHeading2 = styled.h1`
    position: relative;
    -webkit-animation: glitch-skew 1s infinite linear alternate-reverse;
    animation: glitch-skew 1s infinite linear alternate-reverse;
    font-size: 24px;
    word-wrap: normal;
    width: 100%;
    position: relative;
    z-index: 1;
    font-family: 'mokoto glitch mark I';
    background: -webkit-linear-gradient(45deg, #ff0468, #f081ff);
    opacity: 0.8;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 52px;
    letter-spacing: 0.1em;
    word-wrap: normal;
    white-space: nowrap;
    word-break: keep-all;
    text-align: center;

    &::before{
    content: "GUIDE";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    left: 2px;
    opacity: 0.5;
    clip: rect(44px, 450px, 56px, 0);
    -webkit-animation: glitch-anim 4s infinite linear alternate-reverse;
    animation: glitch-anim 4s infinite linear alternate-reverse;
    text-shadow: -2px 0 #ff0468;
    word-wrap: normal;
    }

    *::before{
    border-width: 0;
    border-style: solid;
    box-sizing: border-box;
    }

    *::after{
    border-width: 0;
    border-style: solid;
    box-sizing: border-box;
    }

    ::after{
    content: "GUIDE";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    left: -2px;
    -webkit-animation: glitch-anim2 3s infinite linear alternate-reverse;
    animation: glitch-anim2 3s infinite linear alternate-reverse;
    opacity: 0.4;
    text-shadow: -2px 0 #f081ff, 2px 2px #ff0063;
    word-wrap: normal;
    border-color: #E2E8F0;


    }

    .animation{
        font-family: mokoto glitch outline;
	font-weight: 400;
	font-size: 30px;
	line-height: 1.1;
	letter-spacing: 4px;
	margin-left: var(--chakra-space-2);
	position: absolute;
	top: 0px;
	left: 0px;
	width: var(--chakra-sizes-full);
	opacity: 0.4;
	white-space: nowrap;
	animation: 5s linear 0s infinite alternate-reverse none running glitch-anim2;
    }

    .glitch-anim{
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: glitch-anim;
    }

    .glitch-anim2{
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: glitch-anim2;
    }
`

const AnimationStyle = styled.h2`
font-family: mokoto glitch outline;
	font-weight: 400;
	font-size: 30px;
	line-height: 1;
	letter-spacing: 4px;
	margin-left: 0.5rem;
	position: absolute;
	top: 0px;
	left: -30px;
	width: 100%;
	opacity: 0.4;
	white-space: nowrap;
	animation: 5s linear 0s infinite alternate-reverse none running glitch-anim2;
`

const GenoGlitch = () => {
  return (
    <GlitchWrap>
    <GenoHeading>
        Genoverse
    </GenoHeading>
    <GenoHeading2>
        GUIDE
    </GenoHeading2>
    <AnimationStyle className='glitch-skew'>
        <img src={img1} alt="" />
    </AnimationStyle>
    </GlitchWrap>
  )
}

export default GenoGlitch