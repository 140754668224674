import React, { useState } from "react";
import {} from "react-router-dom";
import styled from "styled-components";
import Discord from "../Icons/Discord";
import Github from "../Icons/Github";
import Twitter2 from "../Icons/Twitter2";
import Logo from "./Logo";

const Section = styled.section`
  width: 100%;
  background-color: ${(props) => props.theme.mainBg};
  position: relative;
  z-index: 90;
  @media (max-width: 64em) {
  }
`;

const NavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  height: ${(props) => props.theme.navHeight};
  margin: 0 auto;
  padding: 1rem;

  position: fixed;
  top: -2px;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.mainBg};
  width: 100%;
  padding: 0 20px;
  max-width: 1920px;

  z-index: 3;

  @media (max-width: 64em) {
    position: fixed;
    top: -2px;
    left: 0;
    right: 0;
    background-color: ${(props) => props.theme.mainBg};
    width: 100%;
    padding: 0 20px;

    .desktop {
      display: none;
    }
    .mobile {
      display: flex;
    }
  }
`;

const Menu = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin-bottom: 0;

  @media (max-width: 64em) {
    /* 1024px */

    position: fixed;
    top: ${(props) => props.theme.navHeight};
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: ${(props) => `calc(100vh - ${props.theme.navHeight})`};
    z-index: 55;
    background-color: ${(props) => `rgba(${props.theme.bodyRgba},0.85)`};
    backdrop-filter: blur(2px);

    transform: ${(props) =>
      props.click ? "translateY(0)" : "translateY(1000%)"};
    transition: all 0.3s ease;
    flex-direction: column;
    justify-content: center;
  }
`;

const MenuItem = styled.li`
  font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1280 - 320)));
  margin: 0 1rem;
  color: ${(props) => props.theme.text};
  cursor: pointer;

  position: relative;
  z-index: 2;

  .mobile {
    display: none;
  }

  @media (max-width: 64em) {
    .desktop {
      display: none;
    }
    .mobile {
      display: flex;
    }
  }

  &::after {
    content: " ";
    display: block;
    width: 0%;
    height: 2px;
    background: ${(props) => props.theme.mainColor};
    transition: width 0.3s ease;
  }
  &:hover::after {
    width: 100%;
  }

  @media (max-width: 64em) {
    margin: 1rem;

    &::after {
      display: none;
    }
  }
`;

const HamburgerMenu = styled.span`
  width: ${(props) => (props.click ? " 2rem" : " 1.5rem")};
  height: 2px;
  background: ${(props) => props.theme.text};

  position: relative;
  transform: ${(props) =>
    props.click
      ? "translateX(-50%) rotate(90deg)"
      : "translateX(-50%) rotate(0)"};

  display: none;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  transition: all 0.3s ease;

  @media (max-width: 64em) {
    /* 1024px */
    display: flex;
  }

  &::after,
  &::before {
    content: " ";
    width: ${(props) => (props.click ? " 1rem" : " 1.5rem")};
    height: 2px;
    right: ${(props) => (props.click ? " -2px" : " 0")};
    background: ${(props) => props.theme.text};
    position: absolute;
    transition: all 0.3s ease;
  }

  &::after {
    top: ${(props) => (props.click ? " 0.3rem" : " 0.5rem")};
    transform: ${(props) => (props.click ? "rotate(-40deg)" : "rotate(0)")};
  }
  &::before {
    bottom: ${(props) => (props.click ? " 0.3rem" : " 0.5rem")};
    transform: ${(props) => (props.click ? "rotate(40deg)" : "rotate(0)")};
  }
`;

const SocialLinkWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

const SocialLink = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;

  a {
    transition: all 0.5s ease;
  }

  a:hover {
    transform: scale(1.2);
  }
`;

const Navigation = () => {
  const [click, setClick] = useState(false);

  const scrollTo = (id) => {
    let element = document.getElementById(id);

    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });

    setClick(!click);
  };

  return (
    <Section id="navigation">
      <NavBar>
        <Logo />
        <HamburgerMenu click={click} onClick={() => setClick(!click)}>
          &nbsp;
        </HamburgerMenu>
        <Menu click={click}>
          <MenuItem onClick={() => scrollTo("home")}>Home</MenuItem>
          <MenuItem onClick={() => scrollTo("about")}>About</MenuItem>
          <MenuItem
            id="StepsAndEnergyLink"
            onClick={() => scrollTo("feature1")}
          >
            Steps And Energy
          </MenuItem>
          <MenuItem id="EnergyToKiLink" onClick={() => scrollTo("feature2")}>
            Energy To KI
          </MenuItem>
          <MenuItem id="KiToEnergyLink" onClick={() => scrollTo("feature3")}>
            KI To Energy
          </MenuItem>
          <MenuItem onClick={() => scrollTo("donation")}>Donation</MenuItem>
          <MenuItem>
            <SocialLink className="mobile">
              <a
                href="https://twitter.com/pest_dao"
                target="_blank"
                rel="noreferrer"
              >
                <Twitter2 />
              </a>
              <a
                href="https://discord.gg/pestdao"
                target="_blank"
                rel="noreferrer"
              >
                <Discord />
              </a>
              <a
                href="https://github.com/Play-Earn-Strive-Together"
                target="_blank"
                rel="noreferrer"
              >
                <Github />
              </a>
            </SocialLink>
          </MenuItem>{" "}
          *
        </Menu>
        <SocialLinkWrap className="desktop">
          <SocialLink>
            <a
              href="https://twitter.com/pest_dao"
              target="_blank"
              rel="noreferrer"
            >
              <Twitter2 />
            </a>
            <a
              href="https://discord.gg/pestdao"
              target="_blank"
              rel="noreferrer"
            >
              <Discord />
            </a>
            <a
              href="https://github.com/Play-Earn-Strive-Together"
              target="_blank"
              rel="noreferrer"
            >
              <Github />
            </a>
          </SocialLink>
        </SocialLinkWrap>
      </NavBar>
    </Section>
  );
};

export default Navigation;
