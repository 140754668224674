import React from "react";
import styled from "styled-components";
import fee8 from "../../assets/fee8.png";
import fee9 from "../../assets/fee9.png";
import CopyToClipboardButton from "../CopyToClipboardButton";

const MySection = styled.section`
  min-height: 100vh;
  width: 100%;
  background-color: ${(props) => props.theme.mainBg};
  color: ${(props) => props.theme.text};
  /* color: ${(props) => props.theme.body}; */

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const MyContainer = styled.div`
  width: 786px;
  margin: auto;
  background: linear-gradient(180deg, #1b232f 0%, #0d1117 100%);
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  padding: 2rem;

  @media (max-width: 64em) {
    width: 100%;
    flex-direction: column;

    & > *:last-child {
      width: 100%;
    }
  }
  @media (max-width: 40em) {
    width: 100%;
  }
`;

const BtnContainer = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: capitalize;

  margin-top: 2rem;

  text-shadow: 1px 1px 2px ${(props) => props.theme.body};

  @media (max-width: 48em) {
    width: 100%;
    justify-content: center;
  }

  p {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1280 - 320)));
  }
`;

const MyTitle = styled.h2`
  background: ${(props) => props.theme.redLinear};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  width: 80%;
  margin: 2rem auto;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
  }
  @media (max-width: 40em) {
  }
  @media (max-width: 30em) {
  }
`;

const SubTitle = styled.p`
  padding: 0 6rem;
  line-height: 1.8rem;
  font-size: calc(12px + (18 - 12) * ((100vw - 320px) / (1280 - 320)));

  @media (max-width: 46em) {
    padding: 0 1rem;
  }
`;

const Deko1 = styled.img`
  position: absolute;
  left: 10rem;
  top: 5rem;
  width: 150px;
  @media (max-width: 40em) {
    display: none;
  }
`;
const Deko2 = styled.img`
  position: absolute;
  right: 5rem;
  top: 35rem;
  width: 150px;
  @media (max-width: 40em) {
    display: none;
  }
`;

const Donation = () => {
  return (
    <MySection id="donation">
      <MyContainer>
        <MyTitle>Donations</MyTitle>
        <SubTitle>
          If you find this site helpful, feel free to tip us a coffee that can
          be turned into more code. We gladly accept USDC, SOL, GENE, sGENE and
          KI on Solana.
        </SubTitle>
        <SubTitle>
          None of the provided data is guaranteed to be correct. Always do your
          own research. None of the provided data is financial advice.
        </SubTitle>
        <BtnContainer>
          <CopyToClipboardButton />
        </BtnContainer>
      </MyContainer>
      <Deko2 src={fee9} alt="Deko9" />
      <Deko1 src={fee8} alt="Deko8" />
    </MySection>
  );
};

export default Donation;
