import React, { Component } from "react";
import styled from "styled-components";
import directimg from "../assets/direct.png";
import fee1 from "../assets/fee1.png";
import fee2 from "../assets/fee2.png";
import Tabs from "../components/Tabs";
import { calculateKIPeriods } from "./Feature2";

import Accordion from "../components/Accordion";
import { Checkbox } from "@material-ui/core";
import FormControlLabel from "@mui/material/FormControlLabel";

const MySection = styled.section`
  margin: 10rem auto;
  width: 100%;
  min-height: 100vh;
  /*   background-color: ${(props) => props.theme.mainBg}; */
  color: ${(props) => props.theme.text};
  overflow: visible;
  /* color: ${(props) => props.theme.body}; */

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 46em) {
    margin: 5rem auto;
  }
`;

const MyContainer = styled.div`
  width: 100%;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: visible;
  @media (max-width: 70em) {
    max-width: 100%;
  }

  @media (max-width: 64em) {
    width: 100%;
    flex-direction: column;

    & > *:last-child {
      width: 80%;
    }
  }
  @media (max-width: 40em) {
    width: 100%;
  }
`;

const MyBox = styled.div`
  width: 100%;
  height: 100%;
  max-width: 650px;
  margin: auto;
  min-height: 50vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 40em) {
    min-height: 50vh;
    margin-left: 0;
  }
`;

const MyTitle = styled.h2`
  background: ${(props) => props.theme.redLinear};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  width: 100%;
  margin: 2rem auto;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
  }
  @media (max-width: 40em) {
  }
  @media (max-width: 30em) {
  }
`;

const TabTitle = styled.div`
  margin-top: 2rem;
  font-style: normal;
  font-weight: 750;
  label {
  }
  labelactive {
    background-color: ${(props) => props.theme.mainBG};
  }
`;
const FlexCon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  margin-top: 2rem;

  @media (max-width: 30em) {
    margin-top: 1rem;
  }
`;

const InputWrap = styled.div`
  width: 590px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem;
  gap: 20px;

  @media (max-width: 40em) {
    width: 250px;
  }
  @media (max-width: 30em) {
    width: 250px;
  }
`;
const InputBox = styled.div`
  /*   display: flex; */
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 20px;
  margin: 0 auto;

  width: 390px;

  span {
    padding: 1rem;
  }

  @media (max-width: 40em) {
    width: 250px;
  }
  @media (max-width: 30em) {
    width: 250px;
  }
`;

const FeatureInput = styled.input`
  border: 1px solid #3f4752;
  background: ${(props) => props.theme.mainBg};
  /*  width: 100%; */
  padding: 0.5rem 1rem;
  color: white;
  overflow: visible;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    background: ${(props) => props.theme.mainBg};
  }

  @media (max-width: 46em) {
    padding: 8px;
  }
`;

const NeededWrap = styled.div`
  /*   display: flex;  */
  justify-items: center;
  align-items: center;
  flex-direction: column;

  p {
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    border-bottom: 2px solid #69dfb5;
    padding: 8px 16px;
  }
`;

const ResultWrap = styled.div`
  /*   display: flex; */
  justify-items: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  margin: 1rem auto;

  min-width: 184px;
  height: auto;
  border: 1px solid #3f4752;
  background-color: transparent;
  padding: 1rem;
  line-height: 125%;

  background: ${(props) => props.theme.redLinear2};

  font-family: "mokoto_glitchregular";
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  position: relative;

  span {
    height: auto;
    display: block;
    text-align: center;
  }
`;

const DirectButton = styled.button`
  background-color: transparent;
  width: 32px;
  height: 32px;
  border: none;

  position: absolute;
  right: 20px;

  transition: 0.2s all ease;

  &:hover {
    transform: scale(1.2);
  }
`;

const Deko1 = styled.img`
  position: absolute;
  width: 400px;
  left: -5rem;
  @media (max-width: 70em) {
    display: none;
  }
`;
const Deko2 = styled.img`
  position: absolute;
  width: 400px;
  right: -2rem;
  bottom: -5rem;
  @media (max-width: 70em) {
    display: none;
  }
`;

const initialEnergyPerStep = 1;
const nurtureModifier = 1;
const baselineDecayValueD = 6;
const maxStepsForEnergy = 100000;
var energyResult = 0;
var energyWithBonus = 0;
var stepsWithBonus = 0;
var checked = false;
var stepsResult = 0;

function CalculateStepsForEnergy() {
  var petLevel = parseInt(document.getElementById("petLvlEtSIn").value);
  var energyTarget = parseInt(document.getElementById("energyTargetIn").value);

  if (
    isNaN(petLevel) ||
    petLevel <= 0 ||
    isNaN(energyTarget) ||
    energyTarget <= 0
  ) {
    stepsResult = 0;
  } else {
    stepsResult = 0;
    var maxInterval = 0;
    var energy = 0;
    var lastEnergy = 0;
    var nearZero = false;
    var energyLimit = 0;
    var sef = CalculateStepEfficiencyFactorSEF(baselineDecayValueD, petLevel);

    while (energy < energyTarget && stepsResult < maxStepsForEnergy) {
      stepsResult += 1000;
      lastEnergy =
        1000 *
        initialEnergyPerStep *
        Math.pow(1 - sef, maxInterval) *
        nurtureModifier;
      energy += lastEnergy;
      maxInterval++;

      //Wird geprüft, damit wir irgendwann die kleinen Energy Beträge nicht mehr aufsummieren, da wir am Energy Limit sind sozusagen.
      if (lastEnergy < 1) {
        energyLimit = Math.ceil(energy);
        nearZero = true;
        break;
      }
    }

    // reset last iteration
    maxInterval--;
    energy -= lastEnergy;
    stepsResult -= 1000;
    // solve interval part for steps
    var missingEnergy = energyTarget - energy;
    stepsResult +=
      missingEnergy /
      (initialEnergyPerStep * Math.pow(1 - sef, maxInterval) * nurtureModifier);

    if (nearZero) {
      MaxEnergyReached(energyLimit);
      return;
    }
  }

  document.getElementById("neededSteps").innerHTML = Math.round(stepsResult);
  document.getElementById("neededSteps").style["display"] = "block";
  document.getElementById("errorSteps").style["display"] = "none";
}

function CalculateEnergyFromSteps() {
  var petLevel = parseInt(document.getElementById("petLvlStEIn").value);
  var totalSteps = parseInt(document.getElementById("stepsUsedIn").value);

  if (
    isNaN(petLevel) ||
    petLevel <= 0 ||
    isNaN(totalSteps) ||
    totalSteps <= 0
  ) {
    energyResult = 0;
  } else if (petLevel > 78) {
    document.getElementById("expectedEnergy").innerHTML = "Not a valid level";
  } else {
    energyResult = 0;
    var maxInterval = Math.floor(totalSteps / 1000);
    var stepsToBank = totalSteps;
    var sef = CalculateStepEfficiencyFactorSEF(baselineDecayValueD, petLevel);

    for (var i = 0; i <= maxInterval; i++) {
      var steps = stepsToBank > 1000 ? 1000 : stepsToBank;

      energyResult +=
        steps * initialEnergyPerStep * Math.pow(1 - sef, i) * nurtureModifier;
      stepsToBank -= steps;
    }
    document.getElementById("expectedEnergy").innerHTML =
      Math.round(energyResult);
  }
}

function MaxEnergyReached(energyLimit) {
  document.getElementById("errorSteps").innerHTML =
    '<span class="small fw-lighter">Energy amount is unreachable due to pet level being to low.</span>' +
    '<br/><span class="small fw-lighter">Max reachable Energy is:</span>' +
    energyLimit;
  document.getElementById("errorSteps").style["display"] = "block";
  document.getElementById("neededSteps").style["display"] = "none";
}

function CalculateStepEfficiencyFactorSEF(baselineDecayValueD, petLevel) {
  var baseSERating = 67.2; // 63.00 SER Base +3.20 wegen dem ersten level +1  aus der Formel
  if (petLevel < 22) {
    return (baselineDecayValueD + (baseSERating - petLevel * 3.2)) / 100;
  } else if (petLevel === 22) {
    return (
      (baselineDecayValueD + (baseSERating - (petLevel - 1) * 3.2 - 1.05)) / 100
    );
  } else {
    return (
      (baselineDecayValueD +
        (baseSERating - (21 * 3.2 + 1.05 + (petLevel - 22) * 0.05))) /
      100
    );
  }
}

function IsGenopet() {
  checked = !checked;
  if (checked) {
    energyWithBonus = energyResult + energyResult / 10;
    document.getElementById("expectedEnergy").innerHTML =
      Math.round(energyWithBonus);
  } else {
    document.getElementById("expectedEnergy").innerHTML =
      Math.round(energyResult);
  }
}

function IsGenopet2() {
  checked = !checked;
  if (checked) {
    stepsWithBonus = stepsResult * 0.9;
    document.getElementById("neededSteps").innerHTML =
      Math.round(stepsWithBonus);
  } else {
    document.getElementById("neededSteps").innerHTML = Math.round(stepsResult);
  }
}

function calculateStepEfficiencyRatingSER(petLevel) {
  if (petLevel < 1) {
    petLevel = 1;
  } else if (petLevel > 77) {
    petLevel = 77;
  }

  return 1 + ((petLevel - 1) * 5) / 100;
}

function transferEnergy() {
  var energy = parseInt(document.getElementById("expectedEnergy").innerHTML);
  energy = isNaN(energy) ? 0 : energy;

  document.getElementById("energyIn").value = energy;
  calculateKIPeriods();

  let element = document.getElementById("feature2");
  element.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  });
}

class Feature1 extends Component {
  render() {
    return (
      <MySection id="feature1">
        <MyContainer id="StepsAndEnergy">
          <MyBox>
            <div>
              <MyTitle>
                Steps And Energy
                <br />
                Calculator
              </MyTitle>
              {/*               <Accordion title="help">

              </Accordion> */}
            </div>

            <Tabs id="steps-and-energy-tabs">
              <TabTitle label="Steps for Energy">
                <FlexCon id="energy-to-steps">
                  <InputWrap>
                    <InputBox>
                      {/* <span>⚡️</span> */}
                      <FeatureInput
                        type="number"
                        placeholder="Enter target energy"
                        id="energyTargetIn"
                        onKeyUp={CalculateStepsForEnergy}
                      />
                    </InputBox>
                    <InputBox>
                      {/* <span>🐉</span> */}
                      <FeatureInput
                        type="number"
                        placeholder="Enter your pet level"
                        id="petLvlEtSIn"
                        onKeyUp={CalculateStepsForEnergy}
                      />
                      <div className="p-2">
                        <FormControlLabel
                          value="start"
                          control={
                            <Checkbox
                              defaultUnChecked
                              onChange={IsGenopet2}
                              style={{
                                color: "#00e676",
                              }}
                            ></Checkbox>
                          }
                          label="10% Genesis Pet Bonus?"
                          labelPlacement="start"
                        />
                      </div>
                    </InputBox>
                  </InputWrap>
                  <NeededWrap>
                    <p>
                      <span>Steps needed:</span>
                      <span className="text-secondary small fw-lighter">
                        {" "}
                        (only valid if all steps are banked on 1 day)
                      </span>
                    </p>
                    <ResultWrap>
                      <span id="neededSteps">0</span>
                      <span id="errorSteps" style={{ display: "none" }}>
                        0
                      </span>
                    </ResultWrap>
                  </NeededWrap>
                </FlexCon>
              </TabTitle>
              <TabTitle label="Energy from Steps">
                <FlexCon id="steps-to-energy">
                  <InputWrap>
                    <InputBox>
                      {/* <span className="input-group-text">👟</span> */}
                      <FeatureInput
                        type="number"
                        placeholder="Enter your steps"
                        id="stepsUsedIn"
                        onKeyUp={CalculateEnergyFromSteps}
                      />
                    </InputBox>
                    <InputBox>
                      {/* <span className="input-group-text">🐉</span> */}
                      <FeatureInput
                        type="number"
                        placeholder="Enter your pet level"
                        id="petLvlStEIn"
                        onKeyUp={CalculateEnergyFromSteps}
                      />
                      <div className="p-2">
                        <FormControlLabel
                          value="start"
                          control={
                            <Checkbox
                              defaultUnChecked
                              onChange={IsGenopet}
                              style={{
                                color: "#00e676",
                              }}
                            ></Checkbox>
                          }
                          label="10% Genesis Pet Bonus?"
                          labelPlacement="start"
                        />
                      </div>
                    </InputBox>
                  </InputWrap>
                  <NeededWrap>
                    <p>
                      <span>Energy earned:</span>
                      <span className="text-secondary small fw-lighter">
                        {" "}
                        (click ⤵️ to use in next section)
                      </span>
                    </p>
                    <ResultWrap>
                      <span id="expectedEnergy">0</span>
                      <DirectButton onClick={transferEnergy}>
                        <img src={directimg} alt="" />
                      </DirectButton>
                    </ResultWrap>
                  </NeededWrap>
                </FlexCon>
              </TabTitle>
            </Tabs>
          </MyBox>
          <Deko1 src={fee1} alt="Deko1" />
          <Deko2 src={fee2} alt="Deko2" />
        </MyContainer>
      </MySection>
    );
  }
}

export default Feature1;
export { CalculateStepsForEnergy as energyToSteps };
