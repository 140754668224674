import React, { useState } from 'react'
import styled from 'styled-components'
import { Minus } from '../Icons/Minus'
import { Plus } from '../Icons/Plus'

const Container = styled.div`
cursor: pointer;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

@media (max-width: 48em) {

}
`

const Title = styled.div`
font-size: ${props => props.theme.fontxs};
opacity: 0.3;
display: flex;
justify-content: space-between;
align-items: center;
flex-direction: row;
`

const Reveal = styled.div`
display: ${props => props.clicked ? 'block' : 'none'};
margin: 0.5rem auto;
color: ${props => `rgba(${props.theme.text}, 0.6)` };

max-width: 600px;
width: 100%;
font-weight: 300;
line-height: 135%;
`


const Name = styled.div`
display: flex;
align-items: center;
`

const Indicator = styled.div`

display: flex;
justify-content: center;
align-items: center;



svg{
    width: 1rem;
    height: auto;
    fill: ${props => props.theme.text};
    opacity: 0.5;
    margin: 0.3rem;
}

@media (max-width: 48em) {
    font-size: ${props => props.theme.fontxl};
}
`


const Accordion = ({title, children}) => {

    const [collapse, setCollapse] = useState(false)

    return (
        <Container>
            <Title onClick={() => setCollapse(!collapse)}>
                <Name>
                    <span>{title}</span>
                </Name>
                {
                    collapse ?
                    <Indicator>
                        <Minus />
                    </Indicator> : <Indicator>
                        <Plus />
                    </Indicator>
                }
            </Title>
            <Reveal clicked={collapse}>
                {children}
            </Reveal>
        </Container>
    )
}

export default Accordion