import { createGlobalStyle } from "styled-components";
import "../assets/fonts/glitch_mark_1-webfont.woff";
import "../assets/fonts/Mokoto Glitch Mark 2.otf";




const GlobalStyles = createGlobalStyle`

/* remove arrows in number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}


*,*::before,*::after{
    margin: 0;
    padding: 0;
}

:root{
    --first-swatch: #168716;
    
}

body {
  font-family: 'Avenir LT Std', Helvetica, sans-serif;
  overflow-x: hidden;
  background-color: #0D1117;
  font-size: calc(16px + (16 - 16) * ((100vw - 320px) / (1280 - 320)));
  font-style: normal;
  font-weight: 400;
  letter-spacing: 135%;
  line-height: 135%;
  width: 100%;
}

h1 {
  font-size: calc(39px + (48 - 39) * ((100vw - 320px) / (1280 - 320)));
  letter-spacing: 0.05rem;
  line-height: 135%;
}
h2 {
  font-size: calc(24px + (34 - 24) * ((100vw - 320px) / (1280 - 320)));
  font-weight: 800;
  line-height: 135%;
  margin-bottom: 2rem;
}
h3 {
  font-size: calc(21px + (24 - 21) * ((100vw - 320px) / (1280 - 320)));
  font-weight: bold;
  line-height: 135%;
  margin-bottom: 2rem;
}

a {
  font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1280 - 320)));
  font-weight: bold;
}

html {
    background-color: #0D1117;
}

h1,h2,h3,h4,h5,h6{
    font-family: 'mokoto_glitch_mark_iiregular', Georgia, 'Times New Roman', Times, serif;
    margin: 0;
    padding: 0;
}
a {
    color: inherit;
    text-decoration: none;
}

ol, ul{
  padding-left: 0rem;
}


.tab-list {
  font-style: normal;
font-weight: 750;
  padding-left: 0;

  &:hover{
    border-bottom: 2px solid #69DFB5;
  }
}

.tab-list-item {
  box-sizing: border-box;
  display: inline-block;
  max-width: auto;
  list-style: none;
  margin-bottom: -1px;
  padding: 8px 16px;
  border-bottom: 2px solid #3F4752;
  &:hover{
    border-bottom: 2px solid #69DFB5;
  }

  @media (max-width: 46em) {
    padding: 8px 0;
  }
}

.tab-list-active {
  background-color: transparent;
  border-bottom: 2px solid #69DFB5;
}

.switch-energy input
{
  display: none;
}

.switch-energy 
{
  display: inline-block;
  width: 60px; /*=w*/
  height: 30px; /*=h*/
  margin: 4px;
  transform: translateY(50%);
  transform:rotate(90deg);
  position: relative;
}

.slider
{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
  box-shadow: 0 0 0 2px #777, 0 0 4px #777;
  cursor: pointer;
  border: 4px solid transparent;
  overflow: hidden;
  transition: 0.2s;
}

.slider:before
{
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #777;
  border-radius: 30px;
  transform: translateX(-30px); /*translateX(-(w-h))*/
  transition: 0.2s;
}

input:checked + .slider:before
{
  transform: translateX(30px); /*translateX(w-h)*/
  background-color: MediumSeaGreen;
}

input:checked + .slider
{
  box-shadow: 0 0 0 2px MediumSeaGreen, 0 0 8px MediumSeaGreen;
}



.success-text{
    background: linear-gradient(93.23deg, #07EC7E 14.42%, #00FFF0 83.97%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}
.warn-text{
    background: linear-gradient(93.23deg, #ECAC07 14.42%, #EBFF00 83.97%);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}
.warn-text{
    background: linear-gradient(93.23deg, #ECAC07 14.42%, #EBFF00 83.97%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.glitch-skew{
  font-family: mokoto glitch outline;
	font-weight: 400;
	font-size: 30px;
	line-height: 1.1;
	letter-spacing: 4px;
	margin-left: var(--chakra-space-2);
	position: absolute;
	top: 0px;
	left: 0px;
	width: var(--chakra-sizes-full);
	opacity: 0.4;
	white-space: nowrap;
	animation: 5s linear 0s infinite alternate-reverse none running glitch-anim2;
}

`

export default GlobalStyles;