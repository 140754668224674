import React from 'react';
import styled from 'styled-components';
import HeroGene from '../../assets/geno-hero.png';
import Logo2 from '../../assets/logo2.png';
import MouseArrow from '../../assets/mouse.svg';
import GenoGlitch from '../GenoGlitch';
import Glitch from '../Glitch';



const Section = styled.section`
min-height: ${props => `calc(100vh - ${props.theme.navHeight})`   };
width: 100%;
margin: 0 auto;
margin-top: 3rem;
margin-bottom: 10rem;
position: relative;

background-image: 'url(${ HeroGene })';
background-color: ${props => props.theme.mainBg};
overflow: visible;
z-index: 1;

background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;

  @media (max-width: 64em) { 
    min-height: ${props => `calc(60vh - ${props.theme.navHeight})`};
    margin-top: 5rem;
    margin-bottom: 5rem;

  }
`

const Container = styled.div`
width: 100%;
min-height: 80vh;
margin: 0 auto;
/* background-color: lightblue; */

display: flex;
justify-content: center;
align-items: center;



@media (max-width: 64em) {
  width: 85%;
}
@media (max-width: 48em) {
  flex-direction: column-reverse;
  width: 100%;

  &>*:first-child{
    width: 100%;
    margin-top: 2rem;
  }
}

&::after {
    content: ' ';
    position: absolute;
bottom: 0%;
width:100%;
background: linear-gradient(180deg, rgba(13, 17, 23, 0) 0%, #0D1117 100%);
height: 40px;
  }
  &::before {
    content: ' ';
    position: absolute;
top: 0%;
width:100%;
background: linear-gradient(180deg, rgba(13, 17, 23, 0) 0%, #0D1117 100%);
height: 40px;
transform: rotate(180deg);
  }

`

const Box = styled.div`
width: 100%;
height: 100%;
display: flex;
position: relative;
flex-direction: column;
justify-content: center;
align-items: center;

margin-top: -2rem;

z-index: 2;

text-align: center;

h1{
  text-align: center;
}
`

const LogoWrap = styled.div`
  position: absolute;
  top: 1rem;
  left: 50%;
  margin-left: -60px;

  img{
    width: 120px;
  }
  @media (max-width: 46em) {
    margin-left: -40px;
    top: 3rem;
    img{
    width: 80px;

  }

  }
`

const MouseScroll = styled.div `

position: absolute;
  bottom: 3rem;
  left: 50%;
  margin-left: -1rem;
	width: 33px;
	height: 62px;
	border: 2px solid #C6C6C6;
	border-radius: 60px;
  opacity: 0.4;

  .dekstop{
    display: block;
  }

  img{
    position: absolute;
    bottom: -2rem;
    left: 4px;
    opacity: 1;
		animation: arrow 2s infinite;
		-webkit-animation: arrow 2s infinite;
  }
  @keyframes arrow {
	to {
		opacity: 0;
		bottom: -3rem;
	}
}

@-webkit-keyframes arrow {
	to {
		opacity: 0;
		bottom: -3rem;
	}
}

	&::before {
		content: '';
		width: 8px;
		height: 8px;
		position: absolute;
		top: 10px;
		left: 50%;
		transform: translateX(-50%);
		background-color: #C6C6C6;
		border-radius: 50%;
		opacity: 0.6;
		animation: wheel 2s infinite;
		-webkit-animation: wheel 2s infinite;
	}

  &::after {
    content: ' ';
    position: absolute;
left: 17.42%;
right: 15.81%;
top: 87.01%;
bottom: 0%;
  }
  


@keyframes wheel {
	to {
		opacity: 0;
		top: 40px;
	}
}

@-webkit-keyframes wheel {
	to {
		opacity: 0;
		top: 40px;
	}
}

@media (max-width: 46em) {
  display: none;
}
`


const Home = () => {
  return (
    <Section id='home' style={{ backgroundImage: `url(${ HeroGene })` }}>
      <Container>
        <Box> 
          <Glitch />
          {/* <GenoGlitch /> */}
        </Box>
        <LogoWrap>
          <img src={ Logo2 } alt="PEST Logo" />
        </LogoWrap>
      </Container>
          <MouseScroll className="desktop">
        <img src={ MouseArrow } alt="Mouse Scroll Animation" />
      </MouseScroll>
    </Section>
  )
}

export default Home