import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tab from './Tab';
import styled from 'styled-components';

const Tabss = styled.div`
    cursor: pointer;
    @media (max-width: 30em) {
      width: 90%;
  }
`

const TabsList = styled.ol`
margin: 2rem auto;
margin-left: 0;

display: flex;
justify-content: center;
align-items: center;
gap: 20px;

@media (max-width: 64em) {

  }
  @media (max-width: 40em) {

  }
  @media (max-width: 30em) {
    margin: 0;
    flex-direction: row;
  }
`

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.children[0].props.label,
    };
  }

  onClickTabItem = (tab) => {
    this.setState({ activeTab: tab });
  }


render() {
    const {
      onClickTabItem,
      props: {
        children,
      },
      state: {
        activeTab,
      }
    } = this;

    return (
      <Tabss>
        <TabsList>
          {children.map((child) => {
            const { label } = child.props;

            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
              />
            );
          })}
        </TabsList>
        <div className="tab-content">
          {children.map((child) => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </Tabss>
    );
  }
}

export default Tabs;