import React, { Component } from "react";
import styled from "styled-components";
import directimg from '../assets/direct.png';
import fee5 from '../assets/fee5.png';
import fee6 from '../assets/fee6.png';
import fee7 from '../assets/fee7.png';
import { energyToSteps } from "./Feature1";

import Accordion from '../components/Accordion';


const MySection = styled.section`
margin: 10rem auto;
  min-height: 100vh;
  width: 100%;
  background-color: ${(props) => props.theme.mainBg};
  color: ${(props) => props.theme.text};

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 40em) {
    margin: 5rem auto;
  }
  @media (max-width: 30em) {
    width: 100%;
  }
`;

const MyContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 70em) {
    max-width: 100%;
  }

  @media (max-width: 64em) {
    width: 100%;
    flex-direction: column;

    & > *:last-child {
      width: 100%;
    }
  }
  @media (max-width: 40em) {
    width: 100%;
  }
`;

const MyBox = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;
  max-width: 650px;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 40em) {
    min-height: 50vh;
    margin-left: 0;
  }
`;

const MyTitle = styled.h2`
  background: ${(props) => props.theme.redLinear};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  width: 80%;
  margin: 2rem auto;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
  }
  @media (max-width: 40em) {
  }
  @media (max-width: 30em) {
  }
`;

const FlexCon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  margin-top: 4rem;
`;

const InputWrap = styled.div`
  width: 590px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem;
  gap: 20px;

  @media (max-width: 40em) {
    width: 250px;
  }
  @media (max-width: 30em) {
    width: 250px;
  }
`;


const FeatureInput = styled.input`
  border: 1px solid #3f4752;
  background-color: transparent;
  color: white;
  width: 100%;
  padding: 1rem 1.3rem;

  &:focus{
    background-color: transparent;
    color: white;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const NeededWrap = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;

  p {
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    border-bottom: 2px solid #69dfb5;
    padding: 0.5rem 1rem;

    @media (max-width: 40em) {
  }
  @media (max-width: 30em) {
    padding: 8px;
    margin: 4px;
  }
  }
`;

const ResultWrap = styled.div`
  /* display: flex; */
  justify-items: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  margin: 1rem auto;

  min-width: 125px;
  width: auto;
  height: auto;
  border: 1px solid #3f4752;
  background-color: transparent;
  padding: 0.6rem;

  background: ${(props) => props.theme.redLinear2};
  font-family: "mokoto_glitchregular";
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  position: relative;

  span {
    height: auto;
 /*    display: flex; */
    justify-items: center;
    align-items: center;
    flex-direction: column;
  }
`;


const DirectButton = styled.button`
background-color: transparent;
width: 32px;
height: 32px;
border: none;

position: absolute;
right: -50px;

transition: 0.2s all ease;

&:hover{
  transform: scale(1.2);
}
`


const Deko1 = styled.img`
position: absolute;
left: 10rem;
top: 5rem;
width: 200px;
@media (max-width: 70em) {
    display: none;
  }
`
const Deko2 = styled.img`
position: absolute;
left: -10rem;
top: 25rem;
width: 650px;
@media (max-width: 70em) {
    display: none;
  }
`
const Deko3 = styled.img`
position: absolute;
right: 10rem;
top: 30rem;
width: 150px;
@media (max-width: 70em) {
    display: none;
  }
`

const kiToEnergyFactor = 1 / 0.022;

function kiToEnergy() {
    var ki = parseFloat(document.getElementById('kiToEnergyIn').value);
    ki = isNaN(ki) ? 0 : ki;

    var energy = ki * kiToEnergyFactor;
    document.getElementById('kiToEnergyOut').innerHTML = Math.round(energy).toLocaleString("en-US");
};

function transferEnergyUp() {
    var energyString = document.getElementById('kiToEnergyOut').innerHTML;
    var energy = parseInt(energyString.replaceAll(',', ''));

    if (isNaN(energy) || energy < 1) {
        return;
    }

    document.getElementsByClassName('tab-list-item')[0].click();
    let element = document.getElementById("feature1");
    element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
    });

    setTimeout(function () {
        document.getElementById('energyTargetIn').value = energy;
        energyToSteps();
    }, 100);
}

/* function petLevel() {
    var petLevelCurrent = parseInt(document.getElementById('petLevelCurrentIn').value);
    var petLevelTarget = parseInt(document.getElementById('petLevelTargetIn').value);
    var dailySteps = parseInt(document.getElementById('petLevelStepsIn').value);

    var stepsResult = 0;
    if (isNaN(petLevelCurrent) || petLevelCurrent <= 0 || isNaN(petLevelTarget) || petLevelTarget <= 0 || petLevelCurrent >= petLevelTarget || isNaN(dailySteps) || dailySteps <= 0) {
        stepsResult = 0;
    } else {
        stepsResult = 10;
    }

    document.getElementById('petLevelStepsOut').value = Math.round(stepsResult);
} */

class Feature3 extends Component {

    render() {
        return <MySection id="feature3">
            <MyContainer id="KiToEnergy">
                <MyBox>
                    <div>
                        <MyTitle>KI to Energy Calculator</MyTitle>
{/*                         <Accordion title="help">

              </Accordion> */}
                    </div>
                    <FlexCon>
                        <InputWrap>
                            <div>
                                {/* <span className="input-group-text">💸</span> */}
                                <FeatureInput
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter your KI"
                                    id="kiToEnergyIn"
                                    onKeyUp={kiToEnergy}
                                />
                            </div>
                        </InputWrap>
                        <NeededWrap>
                            <p>Energy returned:</p>
                            <ResultWrap>
                                <span id="kiToEnergyOut">0</span>
                                <DirectButton
                                    onClick={transferEnergyUp}
                                >
                                    <img src={directimg} alt="" />
                                </DirectButton>
                                <DirectButton
                                    onClick={transferEnergyUp}
                                >
                                    ⤵️
                                </DirectButton>
                            </ResultWrap>
                        </NeededWrap>
                    </FlexCon>
                </MyBox>
            </MyContainer>
            <Deko2 src={fee5} alt="Deko5" />
            <Deko1 src={fee6} alt="Deko6" />
            <Deko3 src={fee7} alt="Deko7" />
        </MySection>;
    };

};

export default Feature3;
export { kiToEnergy };
