import React from 'react'
import styled from 'styled-components'


import img1 from '../../assets/team/continental.webp';       
import img2 from '../../assets/team/Andrei.png';     
import img3 from '../../assets/team/asp.webp';     //jlee
import img4 from '../../assets/team/onarios.webp';
import img5 from '../../assets/team/rightone.webp';    
import img6 from '../../assets/team/3ise.png';
import img7 from '../../assets/team/miner.png';    //GTMiner
import img8 from '../../assets/team/asp.webp';
import img9 from '../../assets/team/imaykay.webp';
import img10 from '../../assets/team/asp.webp';     //nyk
import img11 from '../../assets/team/asp.webp';   //neylox


const MySection = styled.section`
  width: 100%;
  background-color: ${(props) => props.theme.mainBg};
  position: relative;
`
const MyContainer = styled.div`
width: 75%;
margin: 2rem auto;

display: flex;
justify-content: space-around;
align-items: center;
flex-wrap: wrap;

@media (max-width: 64em) {
  width: 80%;
}
@media (max-width: 48em) {
  width: 90%;

}
`
const MyTitle = styled.h2`
  background: ${(props) => props.theme.redLinear};
  font-size: ${(props) => props.theme.fontxxl};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;


  text-align: center;

  width: 80%;
  margin: 2rem auto;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
  }
  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontxlg};
  }
`;


const Item = styled.div`
width: calc(20rem - 4vw);
padding: 1rem 0;
color: ${props => props.theme.body};
margin: 2rem 1rem;
position: relative;
z-index: 5;

backdrop-filter: blur(4px);

/* border: 2px solid ${props => props.theme.text}; */
border-radius: 20px;

&:hover{
  img{
    transform: translateY(-2rem) scale(1.2);
  }
}

@media (max-width: 64em) {
  width: auto;
}
`

const ImageContainer = styled.div`
width: 100%;
margin: 0 auto;

background: linear-gradient(180deg, #1B232F 0%, #0D1117 100%);
border-radius: 8px;
border: 1px solid ${props => props.theme.text};
padding: 1rem;

border-radius: 20px;
cursor:pointer;

img{
  width: 100%;
  height: auto;
  transition: all 0.5s ease;
  
}

@media (max-width: 46em) {
  width: 90%;
}
`

const Name = styled.h2`
font-size:  ${props => props.theme.fontlg};
/* display: flex; */
align-items: center;
justify-content: center;
text-transform: uppercase;
background: ${props => props.theme.redLinear2};
text-align:center;

-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
margin-top: 1rem;
`
const Position = styled.h3`
font-size:  ${props => props.theme.fontmd};
font-family: 'Avenir LT Std', Helvetica, sans-serif;
display: flex;
align-items: center;
justify-content: center;
text-transform: capitalize;
color: ${props => `rgba(${props.theme.textRgba}, 0.9)`};
margin-top: 1rem;
font-weight: 400;
`
const TwitterL = styled.a`

`

const SubTextLight = styled.p`
/*   font-size: ${(props) => props.theme.fontlg}; */
  color: ${(props) => props.theme.text};
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1280 - 320)));
  line-height: 135%;
  align-self: center;
  width: 85%;
  max-width:750px;
  margin: 2rem auto;
  font-weight: 400;
  text-align: center;
  margin-bottom: 14rem;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
/*     font-size: ${(props) => props.theme.fontsm}; */
  }
  @media (max-width: 40em) {
/*     font-size: ${(props) => props.theme.fontsm}; */
  }
  @media (max-width: 30em) {
/*     font-size: ${(props) => props.theme.fontxxs}; */
padding: 0 32px;
  }
`

const MyBox = styled.div`
  width: 100%;


  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 40em) {

  }
`

const MemberComponent = ({img, name=" ", position=" "}) => {


  return(
    <Item>
      <ImageContainer>
        <img src={img} alt={name} />
      </ImageContainer>
      <Name>{name}</Name>
      <Position>{position}</Position>
      <TwitterL>{}</TwitterL>
    </Item>
  )
}



const Team = () => {
  return (
    <MySection id='team'>
        <MyBox>
          <MyTitle>About Us</MyTitle>
          <SubTextLight>
          We are P.E.S.T - Play.Earn.Strive.Together <br />
The first (official) German-speaking Genopets-Guild, founded by moderators and members of the <br /> German Genopets channel.  <br />
<br />
Actually we are just some random dudes, fitness enthusiasts and gamers who are very passionate about Crypto and NFTs, coming together in discord and babbling about projects we find cool. <br />
Genopets was the one which united us in the first place, <br /> a project one of a kind. <br />
<br />
Our mission is to gather all German-speaking players from Germany, Austria and Switzerland who are looking to dive deeper into the game and who are searching for a strong community to support common goals, such as crafting, leveling and renting habitats.

          </SubTextLight>
        </MyBox>
     {/* <ConfettiComponent />  */}
      <MyTitle>Team</MyTitle>
      <MyContainer>
        <MemberComponent img={img1} name="Continental" position="Leader" />
        <MemberComponent img={img2} name="Andrei" position="Co-Leader" />
        <MemberComponent img={img3} name="JLee" position="Co-Leader" />
        <MemberComponent img={img4} name="Onarios" position="Treasurer" />
        <MemberComponent img={img5} name="RightOne" position="Treasurer" />
        <MemberComponent img={img6} name="3ise" position="Supervisor" />
        <MemberComponent img={img7} name="GTMiner" position="Supervisor" />
        <MemberComponent img={img8} name="asp" position="Genetic-Coder" />
        <MemberComponent img={img9} name="imaykay" position="Genetic-Coder" />
        <MemberComponent img={img10} name="0x_Nyk" position="Genetic-Coder" />
        <MemberComponent img={img11} name="NeyloxVFX" position="Genetic-Coder" />

      </MyContainer>
    </MySection>
  )
}

export default Team