// This file contains variables for different themes

export const light = {

    body:"#fff",
    text:"#202020",
    bodyRgba : "255, 255, 255", // black shade
    textRgba:"32,32,32",

    carouselColor: '#EEEDDE',

    fontxs:'0.75em',
    fontsm:'0.875em',
    fontmd:'1em', // 1em = 16px
    fontlg:'1.25em',
    fontxl:'2em',
    fontxxl:'3em',
    fontxxxl:'4em',

    fontButton:'0.875em',


    navHeight: '7rem',

}

export const dark = {

    body: "#202020",
    text: "#fff",
    bodyRgba :  "32,32,32", 
    textRgba: "255, 255, 255", // black shade

    carouselColor: "#191F27",

    mainColor: '#69DFB5',
    mainLinear: "linearGradient(93.23deg, #DD07EC 14.42%, #FF0063 83.97%)",
    secondLinear: "linear-gradient(93.23deg, #DD07EC 14.42%, #FF0063 83.97%)",
    mainBg: '#0D1117',

    redLinear: "linear-gradient(93.23deg, #DD07EC 14.42%, #FF0063 83.97%)",
    redLinear2: "#FF0063",
    greenColor: "linear-gradient(93.23deg, #07EC7E 14.42%, #00FFF0 83.97%)",
    yellowColor: "linear-gradient(93.23deg, #ECAC07 14.42%, #EBFF00 83.97%)",

    fontxs:'0.75em',
    fontsm:'0.875em',
    fontmd:'1em', // 1em = 16px
    fontlg:'1.25em',
    fontxl:'2em',
    fontxxl:'3em',
    fontxxxl:'5em',

    fontButton:'0.875em',


    navHeight: '4rem',

    maximWidth: '1204',

}