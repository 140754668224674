import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import logo from '../logo.svg'


const LogoText = styled.div`
transition: all 0.2s ease;
&:hover {
    transform: scale(1.1);
}

@media (max-width: 64em) {
  font-size: ${props => props.theme.fontxxl};

}
`

const NykLogo = styled.img`
width: 8rem;
height: auto;
`

const Logo = () => {
  return (
    <LogoText>
        <Link to="/">
            <NykLogo src={logo} alt="Nyk Logo" />
        </Link>
    </LogoText>
  )
}

export default Logo