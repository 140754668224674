import React from "react";
import styled from "styled-components";

import aboutimage from '../../assets/aboutimage.png'


const MySection = styled.section`
  width: 100%;
  background-color: ${(props) => props.theme.mainBg};
  /* color: ${(props) => props.theme.body}; */

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 1rem auto;
`;

const MyContainer = styled.div`
  width: 90%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 70em) {
    max-width: 90%;
  }

  @media (max-width: 64em) {
    width: 100%;
    flex-direction: column;

    &>*:last-child{

    }
  }
  @media (max-width: 40em) {
    flex-direction: column;
  }
`

const MyBox = styled.div`
  width: 100%;


  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 40em) {

  }
`

const MyTitle = styled.h2`
   background: ${props => props.theme.redLinear};
/*   font-size: ${props => props.theme.fontxxl}; */

  -webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
  width: 80%;
  margin: 0 auto;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
  }
  @media (max-width: 40em) {
/*     font-size: ${(props) => props.theme.fontxl}; */
  }
  @media (max-width: 30em) {
/*     font-size: ${(props) => props.theme.fontxlg}; */
  }
`

const SubTextLight = styled.p`
/*   font-size: ${(props) => props.theme.fontlg}; */
  color: ${(props) => props.theme.text};
  font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1280 - 320)));
  line-height: 135%;
  align-self: flex-start;
  width: 85%;
  max-width:980px;
  margin: 2rem auto;
  font-weight: 400;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
/*     font-size: ${(props) => props.theme.fontsm}; */
  }
  @media (max-width: 40em) {
/*     font-size: ${(props) => props.theme.fontsm}; */
  }
  @media (max-width: 30em) {
/*     font-size: ${(props) => props.theme.fontxxs}; */
  }
`


const Box2 = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0rem;

  @media (max-width: 40em) {
    flex-direction: row;
    margin-bottom: 0rem;
  }
  @media (max-width: 30em) {
    flex-direction: row;
    grid-template-columns: repeat(1, 1fr);
  }
`

const AboutWrap = styled.a`

`
const AboutTitle = styled.h3`
  background: ${props => props.theme.redLinear};
/*   font-size: ${props => props.theme.fontlg}; */

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  margin: 0 auto;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
  }
  @media (max-width: 40em) {
/*     font-size: ${(props) => props.theme.fontlg}; */
  }
  @media (max-width: 30em) {
/*     font-size: ${(props) => props.theme.fontmd}; */
  }
`
const AboutSubTitle = styled.p`
  color: ${props => props.theme.text};
  font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1280 - 320)));
 /*  font-size: ${props => props.theme.fontmd}; */
  margin: 1rem auto;
  width: 90%;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
  }
  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontsm};
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontxs};
    width: 100%;
  }
`

const Box3 = styled.div`
  width: 100%;
  margin: 0;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(1, 1fr);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0rem;

  img{
    width: 100%;
  }

  @media (max-width: 40em) {
    flex-direction: row;
    margin-bottom: 0rem;
  }
  @media (max-width: 30em) {
    flex-direction: row;
    grid-template-columns: repeat(1, 1fr);
  }
`

const About = () => {
  return (
    <MySection id="about">
      <MyContainer>
        <MyBox>
          <MyTitle>About Genoverse Guide</MyTitle>
          <SubTextLight>
            We grinded the Genopets Litepaper countless times so we could take and compress all the relevant data into this neat looking and easily-accessible calculator.
            Here you can find out how much Energy you get from your steps, how much KI you get from your Energy and so much more with only a few clicks. <br />
<br />
            However, Genoverse Guide is not set to be only a Step-Energy-KI calculator, but rather a hitchhiker's guide for all players around the globe who are looking to travel through the Genoverse. <br />
            It is made for curious babies and also for greedy dragons, basically anyone who wants to prepare for what's to come.  <br />
<br />
            Soon Genoverse Guide will be filled with tips, tricks and more tools to support the whole Genopets community and eventually, some day, it might terraform into a new habitat for players. <br />
            Your pets are not the only ones who deserve a nice place to hang out and chill. <br />
            Stay tuned for more! 
          </SubTextLight>
        </MyBox>
        <Box3>
        <img src={ aboutimage } alt="" />
        </Box3>
        <Box2>
          <AboutWrap href="#feature1">
            <img src="" alt="" />
            <AboutTitle>Steps To Energy</AboutTitle>
            <AboutSubTitle>Calculate your steps needed for Energy and Energy for Steps.</AboutSubTitle>
          </AboutWrap>
          <AboutWrap href="#feature2">
            <img src="" alt="" />
            <AboutTitle>Energy To KI</AboutTitle>
            <AboutSubTitle>Calculate your Energy to KI and find out how much Overflow you generate.</AboutSubTitle>
          </AboutWrap>
          <AboutWrap href="#feature3">
            <img src="" alt="" />
            <AboutTitle>KI To Energy</AboutTitle>
            <AboutSubTitle>Calculate your KI to Energy and find out how much Energy is returned.</AboutSubTitle>
          </AboutWrap>
        </Box2>
      </MyContainer>
    </MySection>
  );
};

export default About;
