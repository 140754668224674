import React from "react";
import styled from "styled-components";
import Discord from "../Icons/Discord";
import Github from "../Icons/Github";
import Twitter2 from "../Icons/Twitter2";
import Logo from "./Logo";
import Mail from "../Icons/Mail";

const Section = styled.section`
  margin-top: 4rem;
  min-height: 30vh;
  width: 100%;
  background-color: ${(props) => props.theme.mainBg};
  color: ${(props) => props.theme.text};
  position: relative;

  display: flex;
  /* justify-content: center;
  align-items: center; */
  flex-direction: column;
`;

const Container = styled.div`
  width: 75%;
  margin: 2rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid ${(props) => props.theme.text};

  @media (max-width: 48em) {
    width: 90%;

    h1 {
      font-size: ${(props) => props.theme.fontxxxl};
    }
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 48em) {
    width: 100%;
  }
`;

const IconList = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem auto;

  & > * {
    padding-right: 0.5rem;
    transition: all 0.2s ease;

    &:hover {
      transform: scale(1.2);
    }
  }
`;

const MenuItems = styled.ul`
  list-style: none;
  width: 50%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 1rem;

  @media (max-width: 48em) {
    display: none;
  }
`;

const Item = styled.li`
  width: fit-content;
  cursor: pointer;

  &::after {
    content: " ";
    display: block;
    width: 0%;
    height: 2px;
    background: ${(props) => props.theme.mainColor};
    transition: width 0.3s ease;
  }
  &:hover::after {
    width: 100%;
  }
`;

const Bottom = styled.div`
  width: 75%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    text-decoration: underline;
  }

  @media (max-width: 48em) {
    flex-direction: column;
    width: 100%;

    span {
      margin-bottom: 1rem;
    }
  }
`;

const Footer = () => {
  const scrollTo = (id) => {
    let element = document.getElementById(id);

    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  return (
    <Section>
      <Container>
        <Left>
          <Logo />
          <IconList>
            <a
              href="https://twitter.com/pest_dao"
              target="_blank"
              rel="noreferrer"
            >
              <Twitter2 />
            </a>
            <a
              href="mailto:team@genoverse.guide"
              target="_blank"
              rel="noreferrer"
            >
              <Mail />
            </a>
            <a
              href="https://discord.gg/pestdao"
              target="_blank"
              rel="noreferrer"
            >
              <Discord />
            </a>
            {/*             <a href="https://github.com/Play-Earn-Strive-Together" target="_blank" rel="noreferrer">
              <Github />
            </a> */}
          </IconList>
        </Left>
        <MenuItems>
          <Item onClick={() => scrollTo("home")}>Home</Item>
          <Item onClick={() => scrollTo("about")}>About</Item>
          <Item onClick={() => scrollTo("feature1")}>Steps And Energy</Item>
          <Item onClick={() => scrollTo("feature2")}>Energy to KI</Item>
          <Item onClick={() => scrollTo("feature3")}>KI to Energy</Item>
        </MenuItems>
      </Container>
      <Bottom>
        <span>
          &copy; {new Date().getFullYear()} P.E.S.T - All rights reserved.
        </span>
        <span>
          Designed and built with &#10084; by{" "}
          <a
            href="https://twitter.com/0x_nyk"
            target="_blank"
            rel="noopener noreferrer"
          >
            Nyk
          </a>
        </span>
      </Bottom>
    </Section>
  );
};

export default Footer;
