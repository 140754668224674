import styled, { ThemeProvider } from "styled-components";
// import { Navigation } from 'swiper';
import GlobalStyles from "./styles/GlobalStyles";
import { dark } from "./styles/Themes";
import "../src/components/genoguide.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./components/genoguide.js";

import Navigation from "./components/Navigation.js";
import Home from "./components/sections/Home.js";
import SecondNav from "./components/SecondNav.js";
import About from "./components/sections/About.js";

import Feature1 from "./components/Feature1";
import Feature2 from "./components/Feature2";
import Feature3 from "./components/Feature3";
import Team from "./components/sections/Team";
import Donation from "./components/sections/Donation";

import Footer from "./components/Footer.js";
import ScrollToTop from "./components/ScrollToTop";

const AppWrap = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  @media (max-width: 46em) {
    overflow: hidden;
  }
`;

function App() {
  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={dark}>
        <AppWrap>
          <Navigation />
          {/*   <SecondNav /> */}
          <Home />
          <About />
          <Feature1 />
          <Feature2 />
          <Feature3 />
          <Donation />
          <Footer />
          <ScrollToTop />
        </AppWrap>
      </ThemeProvider>
    </>
  );
}

export default App;
