import React from 'react';
import GlitchText from 'react-glitch-effect/core/GlitchText';
import styled from 'styled-components';

const MainHeading = styled.h1`
text-align: center;
background: ${props => props.theme.redLinear};
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
cursor: crosshair;

@media (max-width: 64em) {
    width: 100%;
    text-align: center;
  }
  @media (max-width: 40em) {
  }
  @media (max-width: 30em) {
  }
  @media (max-width: 375px) {
  }
`

const Brecher = styled.div`
height: 30px;
`

 const Glitch = () => {
   return (
     <MainHeading>
         <GlitchText color1="#FF0063" color2="rgba(255, 0, 99, 1)"  >
           Genoverse
         </GlitchText>
          <Brecher />
         <GlitchText color1="#69DFB5" color2="rgba(255, 0, 99, 1)"  >
           Guide
         </GlitchText>
     </MainHeading>
   )
 };
 
 export default Glitch;

 /* props: 
 * color1: string,
 * color2: string,
 * component: string,
 * duration: number,
 * iterationCount: string,
 * disabled: bool,
 * onHover: bool,
 * */