import React, { useLayoutEffect, useRef, useState } from "react";
import { useWindowScroll } from "react-use";

import styled from "styled-components";

const Navigation = styled.nav`
  position: fixed;
  bottom: 0;
  left: 50%;
  margin-left: -365px;
  justify-content: center;
  z-index: 50;
  opacity: 40%;

  @media (max-width: 64em) {
      min-width: 100% !important;
      left: 0;
      margin-left: 0;
    }

  ul {
    list-style: none;
    display: flex;
    gap: 20px;

    @media (max-width: 64em) {
      display: flex;
      width: 100%;
      flex-direction: row;
      /* grid-template-columns: repeat(3, "auto"); */
      gap: 0px;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    li {
      padding: 8px 16px;
      color: ${(props) => props.theme.text};
      cursor: pointer;

      @media (max-width: 64em) {
      padding: 0.5rem;
    }
    }
  }
`;



const MenuItem = styled.li`
  margin: 0 1rem;
  color: ${(props) => props.theme.text};
  cursor: pointer;

  position: relative;
  z-index: 2;

  &::after {
    content: " ";
    display: block;
    width: 0%;
    height: 2px;
    background: ${(props) => props.theme.mainColor};
    transition: width 0.3s ease;
  }
  &:hover::after {
    width: 100%;
  }

  @media (max-width: 64em) {

    margin: 0;
    padding: 0.5rem;

    &::after {
      display: none;
    }
  }
`;

const SecondNav = () => {
  const [click, setClick] = useState(false);

  const scrollTo = (id) => {
    let element = document.getElementById(id);

    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });

    setClick(!click);
  };

  const ref = useRef(null);
  const { y } = useWindowScroll();

  useLayoutEffect(() => {
    if (y > 200) {
      ref.current.style.display = "flex";
    } else {
      ref.current.style.display = "none";
    }
  }, [y]);


    return <Navigation ref={ref} id="bottomnav">
      <ul>
        <li>
        <MenuItem onClick={() => scrollTo("feature1")}>
          Steps and Energy
          </MenuItem>
        </li>
        <li>
          <MenuItem onClick={() => scrollTo("feature2")} id="EnergyToKiLink">
            Energy to KI
          </MenuItem>
        </li>
        <li className="nav-item">
          <MenuItem onClick={() => scrollTo("feature3")} id="KiToEnergyLink">
            KI to Energy
          </MenuItem>
        </li>
      </ul>
    </Navigation>;



/*     return (
      <Navigation id="bottomnav">
        <ul>
          <MenuItem onClick={() => scrollTo("feature1")}>
            Steps and Energy
          </MenuItem>
          <li>
            <MenuItem onClick={() => scrollTo("feature2")} id="EnergyToKiLink">
              Energy to KI
            </MenuItem>
          </li>
          <li className="nav-item">
            <MenuItem onClick={() => scrollTo("feature3")} id="KiToEnergyLink">
              KI to Energy
            </MenuItem>
          </li>
        </ul>
      </Navigation>
    ); */





  };

export default SecondNav;
