import { Button, Snackbar } from "@mui/material";
import { useState } from "react";

const CopyToClipboardButton = () => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(
      "6doddfDXqhnC3D91Qigee2pGkqeyKsuHuyvVF84fobfA"
    );
  };
  return (
    <>
      <Button
        onClick={handleClick}
        sx={{ backgroundColor: "white", padding: 2 }}
      >
        Copy Wallet Address
      </Button>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message="Copied to clipboard"
      />
    </>
  );
};
export default CopyToClipboardButton;
