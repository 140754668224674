import React, { Component } from "react";
import styled from "styled-components";
import directimg from '../assets/direct.png';
import fee3 from '../assets/fee3.png';
import fee4 from '../assets/fee4.png';
import Accordion from '../components/Accordion';
import { kiToEnergy } from "./Feature3";


const MySection = styled.section`
margin: 10rem auto;
  min-height: 100vh;
  width: 100%;
  background-color: ${(props) => props.theme.mainBg};
  color: ${(props) => props.theme.text};

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 40em) {
  }
  @media (max-width: 70em) {
    
    margin: 5rem auto;
  }
`;

const MyContainer = styled.div`
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 70em) {
    max-width: 100%;
  }

  @media (max-width: 64em) {
    width: 100%;
    flex-direction: column;

    & > *:last-child {
      width: 100%;
    }
  }
  @media (max-width: 40em) {
    width: 100%;
    margin: 0 1rem;
  }
`;

const MyBox = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;
  max-width: 650px;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  @media (max-width: 40em) {
    min-height: 50vh;
    margin-left: 0;
  }
`;

const MyTitle = styled.h2`
  background: ${(props) => props.theme.redLinear};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;


  width: 100%;
  margin: 0 auto;
margin-bottom: 1rem;
  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
  }
  @media (max-width: 40em) {
  }
  @media (max-width: 30em) {
  }
`;

const FlexCon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  margin-top: 1.5rem;

  @media (max-width: 30em) {
    width: 100%;
  }
`;

const InputWrap = styled.div`
  width: 100%;
  margin: 1rem auto;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 0.5rem;
  gap: 10px;

  @media (max-width: 40em) {
    width: 100%;
    margin: 0;
  }
  @media (max-width: 30em) {
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 1rem 1rem;
  }
`;

const FeatureInput = styled.input`
  border: 1px solid #3f4752;
  background-color: transparent;
  color: white;
  width: 100%;
  padding: 8px 16px;

  &:focus{
    background-color: transparent;
    color: white;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const NeededWrap = styled.div`
/*   display: flex; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1280 - 320)));

  p {
    width: 100%;
/*     display: flex; */
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-bottom: 2px solid #69dfb5;
    padding: 0.5rem 1rem;
   
    
  @media (max-width: 40em) {
  }
  @media (max-width: 30em) {

  }
  }
`;

const ResultWrap = styled.div`
/*   display: flex; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: visible;
  margin: 0.3rem auto;

  min-width: 150px;
  width: auto;
  height: auto;
  border: 1px solid #3f4752;
  background-color: transparent;
  padding: 0.5rem;

  background: ${(props) => props.theme.redLinear2};
  font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1280 - 320)));
  font-family: "mokoto_glitchregular";
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  position: relative;

  span {
    height: auto;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    flex-direction: column;
   
  }

  
  @media (max-width: 40em) {
  }
  @media (max-width: 30em) {
    min-width: 114px;
    width: auto;
    overflow: visible;
  }
`;

const DirectButton = styled.button`
  background-color: transparent;
  width: 32px;
  height: 32px;
  border: none;

  position: absolute;
  z-index: 50;
  right: 20px;

  transition: 0.2s all ease;

  &:hover {
    transform: scale(1.2);
  }

  @media (max-width: 46em) {
    right: 20px;
    bottom: 15px;
    width: 15px;
    height: 15px;
  }
`;

const NeededFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  width: 100%;

  @media (max-width: 40em) {
  }
  @media (max-width: 30em) {

  }
`;

const NeededFlex2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const NeededWrap2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  gap: 10px;

  p {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 8px;
    margin: 0 auto;

    
  @media (max-width: 40em) {
  }
  @media (max-width: 30em) {
    flex-direction: column;
    width: 90%;
    margin: auto;
  }
  }

  @media (max-width: 40em) {
  }
  @media (max-width: 30em) {
    flex-direction: row;
    gap: 10px;
  }
`;

const FeatureSelect = styled.select`
  border: 1px solid #3f4752;
  background-color: transparent;
  width: 100%;
  color: white;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  option {
    background: ${(props) => props.theme.mainBg};
  }
`;

const ResultWrap2 = styled.div`
/*   display: flex; */
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin: 0.5rem auto;

  width: 150px;
  height: auto;
  border: 1px solid #3f4752;
  background-color: transparent;
  padding: 0.6rem;



  background: ${(props) => props.theme.redLinear2};
  font-family: "mokoto_glitchregular";
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  position: relative;

  @media (max-width: 64em) {

  }
  @media (max-width: 40em) {

  }
  @media (max-width: 30em) {
    min-width: 80px;
    max-width: 114px;
  }

  span {
    height: auto;
/* s */
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1280 - 320)));
  }
`;

const MyControl = styled.div`
/* display: flex; */
flex-direction: column;
gap: 20;

@media (max-width: 64em) {

  }
  @media (max-width: 40em) {
  
  }
  @media (max-width: 30em) {
    flex-direction: column;
    gap: 0;
  }
`


const Deko1 = styled.img`
position: absolute;
right: -7rem;
width: 600px;
bottom: 0;
@media (max-width: 90em) {
    display: none;
  }
`
const Deko2 = styled.img`
position: absolute;
left: -10rem;
width: 400px;
top: 10rem;
@media (max-width: 90em) {
    display: none;
  }
`

const KiSpan = styled.span`
  background-color: transparent;
    color: ${(props) => props.theme.text};
`


const habitats = {
    None: {
        kiCap: 0,
    },
    "Normal Lvl 1": {
        kiCap: 30,
    },
    "Normal Lvl 2": {
        kiCap: 50,
    },
    "Normal Lvl 3": {
        kiCap: 70,
    },
    "Genesis Lvl 1": {
        kiCap: 40,
    },
    "Genesis Lvl 2": {
        kiCap: 60,
    },
    "Genesis Lvl 3": {
        kiCap: 80,
    },
};
const connectedHabitatBonus = 1.1;
const energyToKi7DayFactor = 0.0095;
const energyToKi10DayFactor = 0.0143;
const energyToKi14DayFactor = 0.02;


const SpanGreen = styled.span`
  background: ${(props) => props.theme.greenColor};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`
const SpanGold = styled.span`
  background: ${(props) => props.theme.yellowColor};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`
const SpanRed = styled.span`
  background: ${(props) => props.theme.redLinear};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`



function calculateKIPeriods() {
    var energy = parseInt(document.getElementById("energyIn").value);
    energy = isNaN(energy) ? 0 : energy;
    var maxKi = maxKICap();

    var sevenDayOut = document.getElementById("sevenDayOut");
    var sevenDayKi = Number(energy * energyToKi7DayFactor);
    formatKI(sevenDayOut, sevenDayKi, maxKi);

    var sevenDayKiOverflowOut = document.getElementById("sevenDayKiOverflowOut");
    var sevenDayEnergyOverflowOut = document.getElementById("sevenDayEnergyOverflowOut");
    var sevenDayKiOverflow = Number(sevenDayKi - maxKi);
    formatKiOverflow(sevenDayKiOverflowOut, sevenDayKiOverflow);
    formatEnergyOverflow(sevenDayEnergyOverflowOut, Number(sevenDayKiOverflow / energyToKi7DayFactor));

    var tenDayOut = document.getElementById("tenDayOut");
    var tenDayKi = Number(energy * energyToKi10DayFactor);
    formatKI(tenDayOut, tenDayKi, maxKi);

    var tenDayKiOverflowOut = document.getElementById("tenDayKiOverflowOut");
    var tenDayEnergyOverflowOut = document.getElementById("tenDayEnergyOverflowOut");
    var tenDayKiOverflow = tenDayKi - maxKi;
    formatKiOverflow(tenDayKiOverflowOut, tenDayKiOverflow);
    formatEnergyOverflow(tenDayEnergyOverflowOut, Number(tenDayKiOverflow / energyToKi10DayFactor));

    var fourteenDayOut = document.getElementById("fourteenDayOut");
    var fourteenDayKi = Number(energy * energyToKi14DayFactor);
    formatKI(fourteenDayOut, fourteenDayKi, maxKi);

    var fourteenDayKiOverflowOut = document.getElementById("fourteenDayKiOverflowOut");
    var fourteenDayEnergyOverflowOut = document.getElementById("fourteenDayEnergyOverflowOut");
    var fourteenDayKiOverflow = fourteenDayKi - maxKi;
    formatKiOverflow(fourteenDayKiOverflowOut, fourteenDayKiOverflow);
    formatEnergyOverflow(fourteenDayEnergyOverflowOut, Number(fourteenDayKiOverflow / energyToKi14DayFactor));
}

function calculateMaxKICap() {
    document.getElementById("kiCapOut").innerHTML = maxKICap().toFixed(2);

    calculateKIPeriods();
}

function maxKICap() {
    var habitat1Cap = habitats[document.getElementById("selectHabitat1").value].kiCap;
    var habitat2Cap = habitats[document.getElementById("selectHabitat2").value].kiCap;
    var habitat3Cap = habitats[document.getElementById("selectHabitat3").value].kiCap;

    var kiCap = habitat1Cap + habitat2Cap + habitat3Cap;
    if ((habitat1Cap > 0 && habitat2Cap > 0) || (habitat1Cap > 0 && habitat3Cap > 0) || (habitat2Cap > 0 && habitat3Cap > 0)) {
        kiCap *= connectedHabitatBonus;
    }

    return Number(kiCap);
}

function formatKI(element, ki, maxKi) {
    formatSuccessWarning(element, ki - maxKi);

    if (maxKi > 0) {
        element.innerHTML = Math.min(ki, maxKi).toFixed(2);
    } else {
        element.innerHTML = ki.toFixed(2);
    }
}

function formatKiOverflow(element, overflowKi) {
    formatSuccessWarning(element, overflowKi);
    element.innerHTML = overflowKi >= 0 ? overflowKi.toFixed(2) : "0";
}

function formatEnergyOverflow(element, overflowEnergy) {
    formatSuccessWarning(element, overflowEnergy);
    element.innerHTML = overflowEnergy >= 0 ? overflowEnergy.toFixed(0).toLocaleString("en-US") : "0";
}

function formatSuccessWarning(element, category) {
    if (category < 0) {
        element.classList.add("success-text");
        element.classList.remove("warn-text");
        element.classList.remove("error-text");
    } else if (category === 0) {
        element.classList.remove("success-text");
        element.classList.add("warn-text");
        element.classList.remove("error-text");
    } else {
        element.classList.remove("success-text");
        element.classList.remove("warn-text");
        element.classList.add("error-text");
    }
}

function transferKi(event) {
    var ki = parseFloat(event.currentTarget.previousSibling.innerHTML);

    if (isNaN(ki) || ki <= 0) {
        return;
    }

    document.getElementById('kiToEnergyIn').value = ki;
    kiToEnergy();

    let element = document.getElementById("feature3");
    element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
    });
}



class Feature2 extends Component {

  render() {
    return (
      <MySection id="feature2">
        <MyContainer id="EnergyToKi">
          <MyBox>
            <div>
              <MyTitle>Energy To Ki Calculator</MyTitle>
              <Accordion title="help">
              Choose your habitats and their levels. <br />
              The order is not important and it does not correlate with the locking time. <br />
              The KI and Energy output correlates with the locking time and your choice of habitats. <br />
              <br />
              <SpanGreen>There is still some space for Energy or KI.</SpanGreen> <br />
              <SpanGold>That is the sweet spot you want to hit.</SpanGold> <br />
              <SpanRed>You overshot the mark.</SpanRed>

              </Accordion>
            </div>

            <FlexCon>
              <InputWrap>
                <div className="input-group">
                  {/* <span className="input-group-text">⚡️</span> */}
                  <FeatureInput
                    type="number"
                    
                    placeholder="Enter your energy"
                    id="energyIn"
                                    onKeyUp={calculateKIPeriods}
                  />
                </div>
              </InputWrap>


              <InputWrap>
                <div className="input-group">
                  {/* <span className="input-group-text">🏝</span> */}
                  <FeatureSelect
                    id="selectHabitat1"
                    className="form-select"
                                    onChange={calculateMaxKICap}
                  >
                                    <option value="None" label="None">None</option>
                                    <option value="Normal Lvl 1" label="Normal Lvl 1">Normal Lvl 1</option>
                                    <option value="Normal Lvl 2" label="Normal Lvl 2">Normal Lvl 2</option>
                                    <option value="Normal Lvl 3" label="Normal Lvl 3">Normal Lvl 3</option>
                                    <option value="Genesis Lvl 1" label="Genesis Lvl 1">Genesis Lvl 1</option>
                                    <option value="Genesis Lvl 2" label="Genesis Lvl 2">Genesis Lvl 2</option>
                                    <option value="Genesis Lvl 3" label="Genesis Lvl 3">Genesis Lvl 3</option>
                  </FeatureSelect>
                </div>
                <div className="input-group">
                  {/* <span className="input-group-text">🏝</span> */}
                  <FeatureSelect
                    id="selectHabitat2"
                    className="form-select"
                                    onChange={calculateMaxKICap}
                  >
                                    <option value="None" label="None">None</option>
                                    <option value="Normal Lvl 1" label="Normal Lvl 1">Normal Lvl 1</option>
                                    <option value="Normal Lvl 2" label="Normal Lvl 2">Normal Lvl 2</option>
                                    <option value="Normal Lvl 3" label="Normal Lvl 3">Normal Lvl 3</option>
                                    <option value="Genesis Lvl 1" label="Genesis Lvl 1">Genesis Lvl 1</option>
                                    <option value="Genesis Lvl 2" label="Genesis Lvl 2">Genesis Lvl 2</option>
                                    <option value="Genesis Lvl 3" label="Genesis Lvl 3">Genesis Lvl 3</option>
                  </FeatureSelect>
                </div>
                <div className="input-group">
                  {/*                   <span className="input-group-text">🏝</span> */}
                  <FeatureSelect
                    id="selectHabitat3"
                    className="form-select"
                                    onChange={calculateMaxKICap}
                  >
                                    <option value="None" label="None">None</option>
                                    <option value="Normal Lvl 1" label="Normal Lvl 1">Normal Lvl 1</option>
                                    <option value="Normal Lvl 2" label="Normal Lvl 2">Normal Lvl 2</option>
                                    <option value="Normal Lvl 3" label="Normal Lvl 3">Normal Lvl 3</option>
                                    <option value="Genesis Lvl 1" label="Genesis Lvl 1">Genesis Lvl 1</option>
                                    <option value="Genesis Lvl 2" label="Genesis Lvl 2">Genesis Lvl 2</option>
                                    <option value="Genesis Lvl 3" label="Genesis Lvl 3">Genesis Lvl 3</option>
                  </FeatureSelect>
                </div>
              </InputWrap>


              <NeededFlex>
                <NeededWrap>
                  <p>7-Day Lock:</p>
                  <ResultWrap>
                    <span id="sevenDayOut">0</span>
                    <DirectButton
                                        onClick={transferKi}
                      >
                        <img src={directimg} alt="" />
                                    </DirectButton>
                  </ResultWrap>
                </NeededWrap>
                <NeededWrap>
                  <p>10-Day Lock:</p>
                  <ResultWrap>
                    <span id="tenDayOut">0</span>
                    <DirectButton
                                        onClick={transferKi}
                      >
                        <img src={directimg} alt="" />
                                    </DirectButton>
                  </ResultWrap>
                </NeededWrap>
                <NeededWrap>
                  <p>14-Day Lock:</p>
                  <ResultWrap>
                    <span id="fourteenDayOut">0</span>
                    <DirectButton
                                        onClick={transferKi}
                      >
                        <img src={directimg} alt="" />
                                    </DirectButton>
                  </ResultWrap>
                </NeededWrap>
              </NeededFlex>
            </FlexCon>

            <FlexCon>


              <NeededFlex2>
                <NeededWrap>
                  <div>
                    <p>
                      <span>KI Overflow:</span>
                      <span className="text-secondary small fw-lighter">
                        {" "}
                        (not claimable)
                      </span>
                    </p>

                  </div>
                </NeededWrap>
                <NeededWrap2>
                  <MyControl>
                    <ResultWrap2>
                                        <span id="sevenDayKiOverflowOut">0</span>
                    </ResultWrap2>
                    <ResultWrap2>
                                        <span id="sevenDayEnergyOverflowOut">(0)</span>
                    </ResultWrap2>
                  </MyControl>
                  <MyControl>
                    <ResultWrap2>
                                        <span id="tenDayKiOverflowOut">0</span>
                    </ResultWrap2>
                    <ResultWrap2>
                                        <span id="tenDayEnergyOverflowOut">(0)</span>
                    </ResultWrap2>
                  </MyControl>
                  <MyControl>
                    <ResultWrap2 >
                                        <span id="fourteenDayKiOverflowOut">0</span>
                    </ResultWrap2>
                    <ResultWrap2>
                                        <span id="fourteenDayEnergyOverflowOut">(0)</span>
                    </ResultWrap2>
                  </MyControl>
                </NeededWrap2>
              </NeededFlex2>
              <NeededFlex>
                <NeededWrap>
                  <p>Max KI Cap:</p>
                  <ResultWrap>
                                    <KiSpan id="kiCapOut">0</KiSpan>
                  </ResultWrap>
                </NeededWrap>
              </NeededFlex>
            </FlexCon>
          </MyBox>
        </MyContainer>
        <Deko2 src={fee3} alt="Deko3" />
        <Deko1 src={fee4} alt="Deko4" />
      </MySection>
    );
  }
}

export default Feature2;
export { calculateKIPeriods };
