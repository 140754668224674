import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const Tabs = styled.li`
  font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1280 - 320)));
` 

class Tab extends Component {
    static propTypes = {
      activeTab: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    };
  
    onClick = () => {
      const { label, onClick } = this.props;
      onClick(label);
    }
  
    render() {
      const {
        onClick,
        props: {
          activeTab,
          label,
        },
      } = this;
  
      let className = 'tab-list-item';
  
      if (activeTab === label) {
        className += ' tab-list-active';
      }
  
      return (
        <Tabs
          className={className}
          onClick={onClick}
        >
          {label}
        </Tabs>
      );
    }
  }
  
  export default Tab;